import { Portal } from "@gorhom/portal";
import React, { FC, useCallback, useMemo } from "react";
import { View } from "react-native";
import { useNavigate, useParams } from "react-router";
import { Spinner } from "@lookiero/aurora";
import { ReplaceableByProjection } from "../../../../projection/boxPreview/boxPreview";
import { BoxPreviewWithFeedbackProductVariantProjection } from "../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { useUpdateBoxPreviewFeedback } from "../../../domain/boxPreviewFeedback/react/useUpdateBoxPreviewFeedback";
import { useViewBoxPreviewWithFeedbackById } from "../../../projection/boxPreviewFeedback/react/useViewBoxPreviewWithFeedbackById";
import { BoxPreviewImageViewTrackerParams, TrackingPage } from "../../../tracking/tracking";
import { useBoxPreviewImageView } from "../../../tracking/useBoxPreviewImageView";
import { useProductDetailView } from "../../../tracking/useProductDetailView";
import { useTrackColorChanged } from "../../../tracking/useTrackColorChanged";
import { ItemDetail as ItemDetailTemplate } from "../../components/templates/itemDetail/ItemDetail";
import { style } from "./ItemDetail.style";

const ItemDetail: FC = () => {
  const { id, productVariantId } = useParams();
  const navigate = useNavigate();
  const navigateToBack = useCallback(() => navigate(-1), [navigate]);

  const { boxPreviewWithFeedback } = useViewBoxPreviewWithFeedbackById({ boxPreviewId: id as string });

  const findProductVariantById = useCallback(
    (id: string) => boxPreviewWithFeedback?.productVariants.find((productVariant) => productVariant.id === id),
    [boxPreviewWithFeedback],
  );
  const boxPreviewItemDetail: BoxPreviewWithFeedbackProductVariantProjection | undefined = useMemo(
    () => findProductVariantById(productVariantId as string),
    [findProductVariantById, productVariantId],
  );

  useProductDetailView({
    country: boxPreviewWithFeedback?.countryCode,
    segment: boxPreviewWithFeedback?.segment,
    page: TrackingPage.ITEM_DETAIL,
    boxPreviewId: boxPreviewWithFeedback?.id,
    productVariantId: boxPreviewWithFeedback?.id,
  });
  const trackBoxPreviewImageView = useBoxPreviewImageView({
    country: boxPreviewWithFeedback?.countryCode,
    segment: boxPreviewWithFeedback?.segment,
    boxPreviewId: boxPreviewWithFeedback?.id,
  });
  const handleOnImageView = useCallback(
    ({ productVariantId, perspective, look, lookProductVariantId, swipe }: BoxPreviewImageViewTrackerParams) => {
      trackBoxPreviewImageView({
        page: TrackingPage.ITEM_DETAIL,
        productVariantId,
        perspective,
        look,
        lookProductVariantId,
        swipe,
      });
    },
    [trackBoxPreviewImageView],
  );

  const trackColorChanged = useTrackColorChanged({
    page: TrackingPage.ITEM_DETAIL,
    country: boxPreviewWithFeedback?.countryCode,
    segment: boxPreviewWithFeedback?.segment,
    boxPreviewId: boxPreviewWithFeedback?.id,
  });
  const { updateReplaceProductVariant } = useUpdateBoxPreviewFeedback({ boxPreviewWithFeedback });
  const handleOnChangedColor = useCallback(
    (replacedFor: string) => {
      const productVariantId = boxPreviewItemDetail?.id as string;

      updateReplaceProductVariant({ productVariantId, replacedFor });
      trackColorChanged({ productVariantId, replacedFor });
      navigateToBack();
    },
    [boxPreviewItemDetail?.id, navigateToBack, trackColorChanged, updateReplaceProductVariant],
  );
  const originalReplacedFor: ReplaceableByProjection = useMemo(
    () =>
      ({
        id: boxPreviewItemDetail?.id,
        color: boxPreviewItemDetail?.color,
        media: boxPreviewItemDetail?.media,
      }) as ReplaceableByProjection,
    [boxPreviewItemDetail?.color, boxPreviewItemDetail?.id, boxPreviewItemDetail?.media],
  );
  const replacedFor: ReplaceableByProjection | null = useMemo(
    () =>
      boxPreviewItemDetail?.replacedFor
        ? ({
            id: boxPreviewItemDetail?.replacedFor?.id,
            color: boxPreviewItemDetail?.replacedFor?.color,
            media: boxPreviewItemDetail?.replacedFor?.media,
          } as ReplaceableByProjection)
        : null,
    [boxPreviewItemDetail?.replacedFor],
  );
  const replaceableBy: ReplaceableByProjection[] = useMemo(
    () => [
      ...(replacedFor ? [replacedFor] : []),
      originalReplacedFor,
      ...(boxPreviewItemDetail?.replaceableBy.filter(
        (replaceableBy) => replaceableBy.id !== replacedFor?.id,
      ) as ReplaceableByProjection[]),
    ],
    [boxPreviewItemDetail?.replaceableBy, originalReplacedFor, replacedFor],
  );

  const looksProductVariants =
    boxPreviewWithFeedback?.looks
      ?.filter((look) => look.includes(productVariantId as string))
      .map((look) => look.map(findProductVariantById)) || [];

  if (!boxPreviewItemDetail) {
    return <Spinner />;
  }

  return (
    <Portal hostName="BoxPreview">
      <View style={style.container}>
        <ItemDetailTemplate
          looksProductVariants={looksProductVariants}
          productVariant={boxPreviewItemDetail}
          replaceableBy={replaceableBy}
          replacedFor={replacedFor || originalReplacedFor}
          onBack={navigateToBack}
          onChangedColor={handleOnChangedColor}
          onImageView={handleOnImageView}
        />
      </View>
    </Portal>
  );
};

export { ItemDetail };
