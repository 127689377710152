import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingPage } from "./tracking";
import { useTracker } from "./useTracker";

interface TrackLooksViewFunction {
  (): void;
}

interface UseLooksViewFunctionArgs {
  readonly country: Country | undefined;
  readonly segment: Segment | undefined;
  readonly page: TrackingPage;
  readonly boxPreviewId: string | undefined;
}

interface UseLooksViewFunction {
  (args: UseLooksViewFunctionArgs): TrackLooksViewFunction;
}

const useLooksView: UseLooksViewFunction = ({ country, segment, page, boxPreviewId }) => {
  const tracker = useTracker();

  const trackLooksView: TrackLooksViewFunction = useCallback(() => {
    if (!(tracker && country && segment && page && boxPreviewId)) {
      return;
    }

    tracker.looksView({ country, segment, page, boxPreviewId });
  }, [tracker, country, page, boxPreviewId, segment]);

  return trackLooksView;
};

export { useLooksView };
