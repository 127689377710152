import React, { FC, useCallback, useMemo } from "react";
import { Linking } from "react-native";
import { useParams } from "react-router";
import { Spinner } from "@lookiero/aurora";
import { Country } from "@lookiero/sty-psp-locale";
import { useViewBoxPreviewById } from "../../../projection/boxPreview/react/useViewBoxPreviewById";
import { TrackingPage } from "../../../tracking/tracking";
import { usePageView } from "../../../tracking/usePageView";
import { Submitted as SubmittedTemplate } from "../../components/templates/submitted/Submitted";
import { useFeatureToggle, useIsFeatureToggleEnabledByCountry } from "../../featureToggle/useFeatureToggle";

const Submitted: FC = () => {
  const { id } = useParams();
  const [boxPreview] = useViewBoxPreviewById({ boxPreviewId: id as string });

  usePageView({
    country: boxPreview?.countryCode,
    segment: boxPreview?.segment,
    page: TrackingPage.THANK_YOU,
    boxPreviewId: boxPreview?.id,
  });

  const discoveryStyleTypeformFeatureToggleEnabled = useIsFeatureToggleEnabledByCountry({
    featureToggleKey: "discoveryStyleTypeform",
    country: boxPreview?.countryCode,
  });

  const { formId, link } = useFeatureToggle({ featureToggleKey: "discoveryStyleTypeform" });
  const handleOnPressBanner = useCallback(() => {
    const country = boxPreview?.countryCode.toLowerCase() as Country;
    const customerId = boxPreview?.customerId;

    if (!country || !customerId || !formId[country]) {
      return;
    }

    Linking.openURL(`${link}/${formId[country]}#user_id=${customerId}`);
  }, [boxPreview?.countryCode, boxPreview?.customerId, formId, link]);

  const chosenProductVariants = useMemo(
    () => boxPreview?.productVariants.filter((productVariant) => productVariant.chosen) || [],
    [boxPreview?.productVariants],
  );

  const discoveryStyleTypeformFeatureToggleActive = useMemo(
    () => discoveryStyleTypeformFeatureToggleEnabled && chosenProductVariants.length <= 2,
    [chosenProductVariants.length, discoveryStyleTypeformFeatureToggleEnabled],
  );

  if (!boxPreview) {
    return <Spinner />;
  }

  return (
    <SubmittedTemplate
      chosenProductVariants={chosenProductVariants}
      discoveryStyleTypeformFeatureToggleEnabled={discoveryStyleTypeformFeatureToggleActive}
      replacedFor={boxPreview.replacedFor}
      onPressBanner={handleOnPressBanner}
    />
  );
};

export { Submitted };
