import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingPage } from "./tracking";
import { useTracker } from "./useTracker";

interface ColorAvailableFunctionArgs {
  readonly productVariantId: string;
  readonly available: string[] | undefined;
  readonly replaceableNumber: number | undefined;
}
interface ColorAvailableFunction {
  (args: ColorAvailableFunctionArgs): void;
}

interface UseTrackColorAvailableFunctionArgs {
  readonly country: Country | undefined;
  readonly segment: Segment | undefined;
  readonly page: TrackingPage;
  readonly boxPreviewId: string | undefined;
}

interface UseTrackColorAvailableFunction {
  (args: UseTrackColorAvailableFunctionArgs): ColorAvailableFunction;
}

const useTrackColorAvailable: UseTrackColorAvailableFunction = ({ country, segment, page, boxPreviewId }) => {
  const tracker = useTracker();

  const colorAvailable: ColorAvailableFunction = useCallback(
    ({ productVariantId, available, replaceableNumber }) => {
      if (
        !(tracker && country && segment && page && boxPreviewId && productVariantId && available && replaceableNumber)
      ) {
        return;
      }

      tracker.colorAvailable({ country, segment, page, boxPreviewId, productVariantId, available, replaceableNumber });
    },
    [boxPreviewId, country, page, segment, tracker],
  );

  return colorAvailable;
};

export { useTrackColorAvailable };
