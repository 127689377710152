import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { ChosenProductVariantTrackerParams } from "./tracking";
import { useTracker } from "./useTracker";

interface TrackChosenProductVariantFunction {
  (args: ChosenProductVariantTrackerParams): void;
}

interface UseChosenProductVariantFunctionArgs {
  readonly country: Country | undefined;
  readonly segment: Segment | undefined;
  readonly boxPreviewId: string | undefined;
}

interface UseChosenProductVariantFunction {
  (args: UseChosenProductVariantFunctionArgs): TrackChosenProductVariantFunction;
}

const useChosenProductVariant: UseChosenProductVariantFunction = ({ country, segment, boxPreviewId }) => {
  const tracker = useTracker();

  const trackChosenProductVariant: TrackChosenProductVariantFunction = useCallback(
    ({ page, productVariantId, chosen, look, isCandidate, auto }) => {
      if (!(tracker && country && segment && page && boxPreviewId && productVariantId)) {
        return;
      }

      tracker.chosenProductVariant({
        country,
        segment,
        page,
        boxPreviewId,
        productVariantId,
        chosen,
        look,
        isCandidate,
        auto,
      });
    },
    [tracker, country, boxPreviewId, segment],
  );

  return trackChosenProductVariant;
};

export { useChosenProductVariant };
