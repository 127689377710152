import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { BoxPreviewImageViewTrackerParams } from "./tracking";
import { useTracker } from "./useTracker";

interface TrackBoxPreviewImageViewFunction {
  (args: BoxPreviewImageViewTrackerParams): void;
}

interface UseBoxPreviewImageViewFunctionArgs {
  readonly country: Country | undefined;
  readonly segment: Segment | undefined;
  readonly boxPreviewId: string | undefined;
}

interface UseBoxPreviewImageViewFunction {
  (args: UseBoxPreviewImageViewFunctionArgs): TrackBoxPreviewImageViewFunction;
}

const useBoxPreviewImageView: UseBoxPreviewImageViewFunction = ({ country, segment, boxPreviewId }) => {
  const tracker = useTracker();

  const trackBoxPreviewImageView: TrackBoxPreviewImageViewFunction = useCallback(
    ({ page, productVariantId, perspective, look, lookProductVariantId, swipe }) => {
      if (!(tracker && country && segment && boxPreviewId && page)) {
        return;
      }

      tracker.boxPreviewImageView({
        country,
        segment,
        page,
        boxPreviewId,
        productVariantId,
        perspective,
        look,
        lookProductVariantId,
        swipe,
      });
    },
    [tracker, country, boxPreviewId, segment],
  );

  return trackBoxPreviewImageView;
};

export { useBoxPreviewImageView };
