import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingPage } from "./tracking";
import { useTracker } from "./useTracker";

interface SubmitBoxPreviewFunctionArgs {
  readonly items: string[];
}

interface SubmitBoxPreviewFunction {
  (args: SubmitBoxPreviewFunctionArgs): void;
}

interface UseTrackSubmitBoxPreviewFunctionArgs {
  readonly country: Country | undefined;
  readonly segment: Segment | undefined;
  readonly page: TrackingPage;
  readonly boxPreviewId: string | undefined;
  readonly customerId: string | undefined;
}

interface UseTrackSubmitBoxPreviewFunction {
  (args: UseTrackSubmitBoxPreviewFunctionArgs): SubmitBoxPreviewFunction;
}

const useTrackSubmitBoxPreview: UseTrackSubmitBoxPreviewFunction = ({
  country,
  segment,
  page,
  boxPreviewId,
  customerId,
}) => {
  const tracker = useTracker();

  const submitBoxPreview: SubmitBoxPreviewFunction = useCallback(
    ({ items }) => {
      if (!(tracker && country && segment && page && boxPreviewId && customerId)) {
        return;
      }

      tracker.submitBoxPreview({ country, segment, page, boxPreviewId, customerId, items });
    },
    [boxPreviewId, country, customerId, page, segment, tracker],
  );

  return submitBoxPreview;
};

export { useTrackSubmitBoxPreview };
