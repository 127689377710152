import React, { FC, ReactNode, useCallback, useEffect, useMemo } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import { Spinner } from "@lookiero/aurora";
import { useUpdateBoxPreviewFeedback } from "../../../domain/boxPreviewFeedback/react/useUpdateBoxPreviewFeedback";
import { useViewBoxPreviewWithFeedbackById } from "../../../projection/boxPreviewFeedback/react/useViewBoxPreviewWithFeedbackById";
import { TrackingPage } from "../../../tracking/tracking";
import { useChosenProductVariant } from "../../../tracking/useChosenProductVariant";
import { usePageView } from "../../../tracking/usePageView";
import { useTrackProductVariantsColorAvailable } from "../../../tracking/useTrackProductVariantsColorAvailable";
import { Items as ItemsTemplate } from "../../components/templates/items/Items";
import { Routes, Tab } from "../../routing/routes";

interface DefaultTab {
  readonly tab: Tab;
  readonly page: TrackingPage;
}

interface ItemsProps {
  readonly children: ReactNode;
}

const Items: FC<ItemsProps> = ({ children }) => {
  const { id, language, tab } = useParams();
  const { boxPreviewWithFeedback, isFetched, dirty } = useViewBoxPreviewWithFeedbackById({
    boxPreviewId: id as string,
  });

  const { clearChosenProductVariants, updateIsCandidateProductVariants } = useUpdateBoxPreviewFeedback({
    boxPreviewWithFeedback,
  });
  const trackChosenProductVariant = useChosenProductVariant({
    country: boxPreviewWithFeedback?.countryCode,
    segment: boxPreviewWithFeedback?.segment,
    boxPreviewId: boxPreviewWithFeedback?.id,
  });
  usePageView({
    country: boxPreviewWithFeedback?.countryCode,
    segment: boxPreviewWithFeedback?.segment,
    page: TrackingPage.ITEMS,
    boxPreviewId: boxPreviewWithFeedback?.id,
  });
  useTrackProductVariantsColorAvailable({
    boxPreviewId: boxPreviewWithFeedback?.id,
    country: boxPreviewWithFeedback?.countryCode,
    segment: boxPreviewWithFeedback?.segment,
    page: TrackingPage.ITEMS,
    productVariants: boxPreviewWithFeedback?.productVariants,
  });

  const navigate = useNavigate();
  const navigateToFeedback = useCallback(
    () => navigate(generatePath(Routes.FEEDBACK, { id: id as string, language: language as string })),
    [navigate, id, language],
  );

  const defaultTab: DefaultTab = useMemo(() => ({ tab: "looks", page: TrackingPage.LOOKS }), []);

  useEffect(() => {
    if (!tab && defaultTab) {
      navigate(
        generatePath(Routes.ITEMS_TABS, {
          id: id as string,
          language: language as string,
          tab: defaultTab.tab,
        }),
        {
          replace: true,
        },
      );
    }
  }, [defaultTab, navigate, tab, id, language]);

  useEffect(() => {
    if (isFetched && !dirty && boxPreviewWithFeedback && defaultTab) {
      const isCandidateProductVariants = boxPreviewWithFeedback.productVariants.filter(
        (productVariant) => productVariant.isCandidate,
      );

      updateIsCandidateProductVariants(isCandidateProductVariants.map(({ id }) => id));

      isCandidateProductVariants.forEach((productVariant) =>
        trackChosenProductVariant({
          page: defaultTab.page,
          productVariantId: productVariant.id,
          chosen: true,
          isCandidate: productVariant.isCandidate,
          auto: true,
        }),
      );
    }
  }, [
    boxPreviewWithFeedback,
    defaultTab,
    dirty,
    isFetched,
    trackChosenProductVariant,
    updateIsCandidateProductVariants,
  ]);

  const dependenciesLoading = !boxPreviewWithFeedback || !tab;

  if (dependenciesLoading) {
    return <Spinner />;
  }

  return (
    <ItemsTemplate
      boxPreviewWithFeedback={boxPreviewWithFeedback}
      tab={tab as Tab}
      onConfirmSelection={navigateToFeedback}
      onDiscard={clearChosenProductVariants}
    >
      {children}
    </ItemsTemplate>
  );
};

export { Items };
