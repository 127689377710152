import React, { FC, useCallback } from "react";
import { useParams } from "react-router";
import { Spinner } from "@lookiero/aurora";
import { useTrackingConversion } from "@lookiero/sty-psp-ab-testing";
import { useSubmitBoxPreview } from "../../../domain/boxPreview/react/useSubmitBoxPreview";
import { useUpdateBoxPreviewFeedback } from "../../../domain/boxPreviewFeedback/react/useUpdateBoxPreviewFeedback";
import { useViewBoxPreviewWithFeedbackById } from "../../../projection/boxPreviewFeedback/react/useViewBoxPreviewWithFeedbackById";
import { useEnvironment } from "../../../projection/environment/react/useEnvironment";
import { TrackingPage } from "../../../tracking/tracking";
import { usePageView } from "../../../tracking/usePageView";
import { useTrackSubmitBoxPreview } from "../../../tracking/useTrackSubmitBoxPreview";
import { Feedback as FeedbackTemplate } from "../../components/templates/feedback/Feedback";

interface HandleSubmitFunctionArgs {
  readonly productVariantIds: string[];
  readonly comment: string;
  readonly replacedFor: Record<string, string>;
}
interface HandleSubmitFunction {
  (args: HandleSubmitFunctionArgs): void;
}

const Feedback: FC = () => {
  const { id } = useParams();
  const {
    maxCommentLength,
    kameleoon: {
      goals: { noItems, oneItemOrMore, twoItemsOrMore, commentRate, feedbackRate },
    },
  } = useEnvironment();

  const { boxPreviewWithFeedback } = useViewBoxPreviewWithFeedbackById({ boxPreviewId: id as string });

  const { updateComment } = useUpdateBoxPreviewFeedback({ boxPreviewWithFeedback });

  const trackConversion = useTrackingConversion();

  const trackSubmitBoxPreview = useTrackSubmitBoxPreview({
    page: TrackingPage.FEEDBACK,
    country: boxPreviewWithFeedback?.countryCode,
    segment: boxPreviewWithFeedback?.segment,
    boxPreviewId: boxPreviewWithFeedback?.id,
    customerId: boxPreviewWithFeedback?.customerId,
  });

  const { status, submit } = useSubmitBoxPreview();
  const handleSubmit: HandleSubmitFunction = useCallback(
    ({ productVariantIds, comment, replacedFor }) => {
      if (productVariantIds.length > 0) {
        if (productVariantIds.length >= 2) {
          trackConversion({ goalId: twoItemsOrMore });
        }
        trackConversion({ goalId: oneItemOrMore });
      } else {
        trackConversion({ goalId: noItems });
      }

      if (comment.length > 0) {
        trackConversion({ goalId: commentRate });
      }

      trackConversion({ goalId: feedbackRate });

      trackSubmitBoxPreview({ items: productVariantIds });
      submit({ productVariantIds, comment, replacedFor });
    },
    [trackConversion, feedbackRate, trackSubmitBoxPreview, submit, oneItemOrMore, twoItemsOrMore, noItems, commentRate],
  );

  usePageView({
    country: boxPreviewWithFeedback?.countryCode,
    segment: boxPreviewWithFeedback?.segment,
    page: TrackingPage.FEEDBACK,
    boxPreviewId: boxPreviewWithFeedback?.id,
  });

  if (!boxPreviewWithFeedback) {
    return <Spinner />;
  }

  return (
    <FeedbackTemplate
      boxPreviewWithFeedback={boxPreviewWithFeedback}
      maxCommentLength={maxCommentLength}
      submitState={status}
      onCommentChanged={updateComment}
      onSubmit={handleSubmit}
    />
  );
};

export { Feedback };
